import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import StudentHeader from "../../../../components/student-header"
import ImageHero from "../../../../components/image-hero"
import SectionHeader from "../../../../components/section-header"
import Footer from "../../../../components/footer"
import VideoEmbed from "../../../../components/video-embed"
import ButtonSign from "../../../../components/button-sign"
import ButtonPurple from "../../../../components/button-purple"
import ButtonOutline from "../../../../components/button-outline"
import QROverlay from "../../../../components/qr-overlay"

const img1 = require("../../../../images/utan-demokratiska-rattigheter/maj-britt-1.jpg")
const img2 = require("../../../../images/utan-demokratiska-rattigheter/maj-britt-2.jpg")
const img3 = require("../../../../images/utan-demokratiska-rattigheter/maj-britt-3.png")
const imgMajBritt = require("../../../../images/utan-demokratiska-rattigheter/maj-britt-portratt.jpg")

import "../../../../scss/site.scss"
//import StickyCta from "../../../../components/sticky-cta"

const MajBrittJohansson = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  //const [caption, setCaption] = useState(null)
  //const [url, setUrl] = useState(null)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  /*const handleShowOverlay = (caption, url) => {
    if (caption) setCaption(caption)
    if (url) setUrl(url)
    setShowOverlay(!showOverlay)
  }*/

  const handleShowOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowOverlay(!showOverlay)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Maj Britt Johansson | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/maj-britt-johansson"
        ></meta>
        <meta property="og:title" content="Maj Britt Johansson"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <StudentHeader />
        <div role="main" id="main" className="o-main c-main-background">
          <ImageHero
            title="Maj-Britt Johansson"
            img={imgMajBritt}
            alt="Porträtt av Maj-Britt Johansson"
          >
            <p>
              Levde 1929-1985. Maj-Britt kom från ett fattigt hem. Hon ansågs
              vara sinnesslö och blev steriliserad så att hon aldrig skulle
              kunna få egna barn. Hon levde hela sitt liv inlåst på olika
              sjukhus.
              <br />
              <span className="c-description">
                Foto: Privat ägo, Kjell Sundstedts fotoalbum
              </span>
            </p>
            <ul className="c-hero__questions">
              <li>
                <h3 className="c-hero__questions__title">
                  Huvudfråga: På vilket sätt saknade Maj-Britt Johansson
                  demokratiska rättigheter?
                </h3>
              </li>
              <li>Lyssna på bildberättelsen och arbeta med källorna.</li>
            </ul>
          </ImageHero>

          <section
            className="c-content-section c-content-section--transparent"
            id="berattelse"
            aria-label="Berättelse"
          >
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <ButtonSign
                    to="https://vimeo.com/527305174"
                    text="Svenskt teckenspråk"
                  />
                  <VideoEmbed url="https://player.vimeo.com/video/519518891?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126" />
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="fotografi-och-diskussionsfragor"
          >
            <SectionHeader
              id="fotografi-och-diskussionsfragor"
              text="Källa 1 Fotografi"
            />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Fotografi
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img1}
                              alt="Svartvitt foto med en stuga. Dörren till stugan är öppen. Det är sommar. På trappen sitter fyra barn som är ganska nära varandra i ålder. Bakom dem står en kvinna."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Fotografi med fyra av Maj-Britts syskon och
                              Maj-Britts mamma Anni utanför deras hus i
                              Glömminge, cirka 1930-tal (privat ägo, Kjell
                              Sundstedts fotoalbum).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer bilden?</li>
                          <li>Varför tror du någon har tagit den?</li>
                          <li>Vilka tankar och frågor väcker bilden?</li>
                          <li>Vad berättar den om Maj-Britt och hennes liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="vykort-och-diskussionsfragor"
          >
            <SectionHeader
              id="vykort-och-diskussionsfragor"
              text="Källa 2 Vykort"
            />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Vykort
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img2}
                              alt="Svartvitt foto med tre byggnader. Utsidorna har många likadana fönster i rader. Husen är två och tre våningar höga och avlånga. Det finns ett staket runt byggnaderna. Längst fram på bilden finns några träd."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Vykort föreställande Nannylunds sjukhus i Eksjö,
                              okänt år.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer vykortet?</li>
                          <li>
                            Varför tror du någon har tagit bilden och gjort ett
                            vykort av den?
                          </li>
                          <li>Vilka tankar och frågor väcker bilden?</li>
                          <li>Vad berättar den om Maj-Britt och hennes liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="brev-och-diskussionsfragor"
          >
            <SectionHeader
              id="brev-och-diskussionsfragor"
              text="Källa 3 Brev"
            />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">Brev</h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img3}
                              alt="Västervik 6 juni 1948. Kära syster Gertrud, Det hjärtligaste tack för paketen som jag fick av dig. Det var så roligt att få något från dig. Hur har du det nu i sommar? Kan du ordna någon ledighet så att du kan komma och hälsa på mig? För det skulle verkligen vara roligt att få se dig. Tänk så länge sedan vi såg varandra. Då var jag åtta år, nu är jag nitton. Hör du något ifrån de andra syskonen? Jag har inget hört från dem på länge. Var är Gunnar och arbetar någonstans? Jag ska tala om för dig att jag fick ett paket från en tant i Eksjö. Hon är så snäll mot mig. Hon ska snart komma och hälsa på mig. Hon ska börja semester den 7 juli och ha till den 4 augusti. Det är verkligen snällt av henne, hon tänker på mig mycket mer än vad mina syskon gör. Du är den enda som sänder mig något. Om du visste vad glad jag blir när jag får höra något från er. Ska du åka hem något i sommar och hälsa på? Om du visste vad glad skulle bli om jag fick åka hem och hälsa på dem. Kan du inte försöka på något sätt att hjälpa mig så att jag kan få komma ut härifrån sjukhuset. För det kan du väl förstå själv att det är inget roligt att gå här instängd när man är så ung. HUr hade du det på midsommarafton? Du var väl ute och hade roligt. Här på sjukhuset hade de midsommarfest. Men jag hade ju ingen glädje av det. För jag fick inte gå. För doktorn sa att jag var för klen att vara med på festen. Men jag får väl mina krafter tillbaka en annan gång, så jag hoppas. Är Ulla kvar på norra Öland än? Jag hör aldrig något ifrån henne. Varför skriver hon aldrig tillbaka till mig? Jag får sluta nu för denna gång. Med många kära hälsningar ifrån din syster Maj-Britt. Skriv snart!."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Brev från Maj-Britt till hennes syster Gertrud, 6
                              juli 1948 (privat ägo, Kjell Sundstedts
                              fotoalbum).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__text">
                        <ButtonPurple
                          text="Brevet förkortat"
                          to="maj-britts-brev"
                        />
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad står det i brevet?</li>
                          <li>Varför tror du Maj-Britt skrev det?</li>
                          <li>
                            Hur tror du Maj-Britt kände sig när hon skrev
                            brevet?
                          </li>
                          <li>Vad berättar det om Maj-Britt och hennes liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="c-image-teaser__action--wide">
            <ButtonOutline
              text="Länk och QR-kod till denna sida"
              title="Maj-Britt Johansson"
              onClick={() => {
                handleShowOverlay(
                  "Utan demokratiska rättigheter: Maj-Britt Johansson",
                  "Öppna med QR-kod eller skriv in länk:",
                  "http://bit.ly/majbritt"
                )
              }}
            ></ButtonOutline>
          </div>
        </div>
        <Footer />
        {showOverlay && (
          <QROverlay
            show={showOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            toggleShow={handleShowOverlay}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default MajBrittJohansson
